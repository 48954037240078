import { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const form = useRef();
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  const validateForm = () => {
    const formData = new FormData(form.current);
    const newErrors = {};

    if (!formData.get('message')) {
      newErrors.message = 'Message is required';
    }
    if (!formData.get('name')) {
      newErrors.name = 'Name is required';
    }
    if (!formData.get('email')) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.get('email'))) {
      newErrors.email = 'Email is invalid';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    emailjs.sendForm('service_sth9vfn', 'template_lpqb7kn', form.current, 'lY5f61R40RYQYroid').then(
      (result) => {
        console.log(result.text);
        setShowModal(true);
        setTimeout(() => setShowModal(false), 2000);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div className='contactFormWrap relative w-full'>
      <form ref={form} onSubmit={sendEmail} className='grid gap-[1.875rem] md:grid-cols-2 grid-cols-1'>
        <div className='fieldBox w-full col-span-2'>
          <label className='text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block'>
            Write a Message
          </label>
          <textarea
            name='message'
            className='border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[8rem] md:h-[13.125rem] p-[1.125rem] text-white'
          ></textarea>
          {errors.message && <p className='text-pinterest font-bold'>{errors.message}</p>}
        </div>
        <div className='fieldBox w-full'>
          <label className='text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block'>
            Name
          </label>
          <input
            type='text'
            name='name'
            className='border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white'
          />
          {errors.name && <p className='text-pinterest font-bold'>{errors.name}</p>}
        </div>
        <div className='fieldBox w-full'>
          <label className='text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block'>
            Email
          </label>
          <input
            type='email'
            name='email'
            className='border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white'
          />
          {errors.email && <p className='text-pinterest font-bold'>{errors.email}</p>}
        </div>
        <div className='fieldbtn mt-0 md:mt-[1.875rem] w-full col-span-2'>
          <button
            className='bg-white text-accent text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1.125rem] py-[14px] hover:bg-accent2 hover:text-white text-center inline-block'
            type='submit'
          >
            Contact Me
          </button>
        </div>
      </form>

      {/* Modal för tackmeddelande */}
      {showModal && (
        <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[rgba(0,0,0,0.5)]'>
          <div className='bg-white p-4 rounded'>
            <p>Thank you for your email!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
